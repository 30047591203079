import React from "react";
import { Typography, Button, Layout, Card, Row, Col } from "antd";
import playables from "../../../playables.json";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

export const TopGames: React.FC = () => {
  return (
    <Layout className="min-h-screen max-w-screen-lg mx-auto bg-transparent px-5 pt-10">
      <Content className="px-4 sm:px-6 md:px-8">
        <Title level={2} className="text-center mb-8">
          Our Top Games
        </Title>
        <Row gutter={[16, 16]} className="space-y-10">
          {playables.map((data, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              className="mb-4 border-none"
            >
              <Card
                className="h-full border-none shadow-none bg-transparent"
                bordered={false}
                bodyStyle={{ padding: 0 }}
              >
                <div
                  className={`flex flex-col space-y-4 md:space-y-0 md:space-x-10 opacity-0 transition-all duration-1000 ease-in-out transform translate-y-10`}
                  ref={(el) => {
                    if (el) {
                      const observer = new IntersectionObserver(
                        ([entry]) => {
                          if (entry.isIntersecting) {
                            el.classList.remove("opacity-0", "translate-y-10");
                            el.classList.add("opacity-100", "translate-y-0");
                            observer.unobserve(el);
                          }
                        },
                        { threshold: 0.1 }
                      );
                      observer.observe(el);
                    }
                  }}
                >
                  <div
                    className={`flex flex-col items-center md:items-stretch md:flex-row w-full md:space-x-8 ${
                      index % 2 !== 0
                        ? "md:flex-row-reverse md:space-x-reverse"
                        : "md:flex-row"
                    }`}
                  >
                    <div
                      className={`w-full md:w-3/5 mb-4 md:mb-0 ${
                        index % 2 === 0 ? "md:mr-4" : "md:ml-4"
                      }`}
                    >
                      <div className="relative w-full pb-[56.25%] rounded-3xl">
                        <img
                          alt={data.imageAlt}
                          src={data.imageUrl}
                          className="absolute top-0 left-0 w-full h-full rounded-3xl object-stretch transition-opacity duration-300 hover:opacity-90"
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-2/5 flex flex-col justify-between md:items-start">
                      <Title
                        level={2}
                        className="text-[#5E17EB] text-2xl font-extrabold rounded-lg md:text-left w-full"
                      >
                        MUST-PLAY GAMES
                      </Title>
                      <div className="text-2xl font-bold mb-2 md:text-left">
                        {data.title}
                      </div>
                      <Paragraph className="text-white bg-[#2E1A47] p-3 rounded-lg mb-3 text-sm text-center w-full">
                        {data.description}
                      </Paragraph>
                      <Paragraph className="mb-4 md:text-left w-full">
                        {data.additionalInfo}
                      </Paragraph>
                      <div className="flex justify-center md:justify-start w-full">
                        <Link
                          to={`/game-demo/${data.alias}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button className="rounded-full text-white bg-gradient-to-r from-[#5E17EB] to-[#8E2DE2] hover:from-[#4A11C0] hover:to-[#7A1ED0] px-8 py-3 text-lg font-bold shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 animate-pulse">
                            Play Now
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Content>
    </Layout>
  );
};
