import "./styles.css";

export const Dice = () => {
  return (
    <svg
      className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-40 lg:h-40 xl:w-40 xl:h-40 custom-animate-bounce"
      viewBox="0 0 190 198"
      fill="none"
    >
      <path
        d="M131.707 32.8332C132.259 32.8332 132.93 33.221 133.207 33.6993L169.621 96.7649C170.173 97.7214 169.725 98.4968 168.621 98.4968L95.7973 98.494C95.245 98.494 94.5734 98.1063 94.2973 97.628L57.3831 33.6964C57.107 33.2181 57.3308 32.8304 57.8831 32.8304L131.707 32.8332Z"
        fill="#F6FAFF"
      ></path>
      <path
        d="M132.041 64.8415C133.112 65.1008 133.764 66.2296 132.992 66.4888L123.47 69.6876C123.125 69.8035 123.038 70.136 123.254 70.5112L129.232 80.8642C129.716 81.7031 128.678 82.0519 127.717 81.3732L115.854 72.9971C115.424 72.6936 114.899 72.5666 114.554 72.6825L105.032 75.8813C104.26 76.1405 102.967 75.2273 103.144 74.5486L105.335 66.1731C105.414 65.8696 105.177 65.4586 104.747 65.1551L92.884 56.7791C91.9227 56.1003 92.1616 55.1872 93.2326 55.4465L106.45 58.6461C106.929 58.7621 107.307 58.6351 107.386 58.3316L109.577 49.9561C109.754 49.2774 111.195 49.6263 111.68 50.4652L117.657 60.8182C117.874 61.1934 118.345 61.5259 118.824 61.6418L132.041 64.8415Z"
        fill="#8ED0FF"
      ></path>
      <path
        d="M56.3628 33.7357C57.1331 32.4014 59.0593 32.4025 59.8281 33.7376L96.2069 96.9141L58.976 161.4C58.2057 162.734 56.2795 162.733 55.5107 161.398L19.1319 98.2215L56.3628 33.7357Z"
        fill="#B5C8E6"
      ></path>
      <path
        d="M169.283 98.7062C170.054 97.3713 169.089 95.7032 167.547 95.7062L94.9369 95.8462C94.5803 95.8469 94.2511 96.0374 94.0728 96.3462L56.2635 161.834C55.8782 162.501 56.3608 163.335 57.1315 163.334L131.478 163.19C131.834 163.19 132.164 162.999 132.342 162.69L169.283 98.7062Z"
        fill="#D0DCEF"
      ></path>
      <ellipse
        cx="55.5699"
        cy="96.647"
        rx="11.9597"
        ry="16.753"
        transform="rotate(0.833003 55.5699 96.647)"
        fill="#294DA5"
      ></ellipse>
      <path
        d="M122.805 142.107L95.9244 142.16C95.0315 142.162 94.5841 141.082 95.2169 140.452L119.88 115.895C120.48 115.297 121.505 115.67 121.581 116.513L123.799 141.017C123.852 141.602 123.392 142.106 122.805 142.107Z"
        fill="#3D75FE"
      ></path>
    </svg>
  );
};
