import React from "react";
import { Typography, Layout, Card } from "antd";
import { YellowTree, Dice, Diamond } from "./svg";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

export const Header: React.FC = () => {
  return (
    <Layout className="max-w-screen-lg mx-auto bg-transparent px-5 pt-10">
      <Content className="px-4 sm:px-6 md:px-8">
        <Card className="mb-8 bg-gradient-to-b from-indigo-500 via-indigo-600 to-indigo-800 text-white rounded-3xl">
          <div className="absolute left-0 top-0 transform -translate-x-1/3 translate-y-10 z-[10]">
            <YellowTree />
          </div>
          <div className="absolute right-0 top-0 transform -translate-x-4 -translate-y-1/2 z-[10]">
            <Dice />
          </div>
          <div className="absolute right-0 bottom-0 transform -translate-x-28 translate-y-1/2 z-[10]">
            <Diamond />
          </div>
          <div className="z-20 space-y-6">
            <Title
              level={5}
              className="text-[#E0E6FF] uppercase text-center cursor-default pt-5 text-xs sm:text-sm md:text-base lg:text-lg"
            >
              Want to discover CloverAds games?
            </Title>

            <h1 className="w-full sm:w-2/3 md:w-1/2 lg:w-1/2 mx-auto text-white mb-5 text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl cursor-default font-bold font-extrabold">
              Play games on <span className="font-black">CloverAds</span>
            </h1>
            <Paragraph className="text-xs sm:text-sm md:text-base lg:text-lg mb-5 text-white text-center cursor-default w-full sm:w-4/5 md:w-3/4 lg:w-2/3 mx-auto">
              Advertising is becoming more interactive with playable ads,
              allowing users to sample your game before installing it,
              ultimately leading to a full and engaging experience.
            </Paragraph>
            {/* <div className="text-center pb-5">
              <Button
                type="primary"
                ghost
                className="bg-indigo text-white hover:text-blue-600 rounded-3xl p-5 border-2 border-white"
              >
                Play games on CloverAds
              </Button>
            </div> */}
          </div>
        </Card>

        <div className="mx-auto my-8 px-4 sm:px-6 md:px-8 w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
          <Paragraph className="text-base text-center">
            CloverAds can be published anywhere - mobile, desktop, tablets. Find
            below a selection of some of the best games made with CloverAds.
          </Paragraph>
        </div>
      </Content>
    </Layout>
  );
};
