import React, { useEffect, useState } from "react";
import { Menu, Drawer, Button, Row, Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/logo_4.png";
import { Link } from "react-router-dom";

const { Item } = Menu;

export const WebsiteName = () => {
  return (
    <div
      className="pl-2"
      style={{
        fontFamily: '"Germania One"',
        fontSize: "2.5rem",
        color: "#3e454f",
        fontWeight: "bold",
      }}
    >
      CloverAds
    </div>
  );
};

export const NavigationBar: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSmoothScroll = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string
  ) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav
      className={`w-full sticky top-0 z-20 transition-all duration-300 ease-in-out ${
        scrollPosition === 0
          ? "bg-transparent"
          : "bg-white bg-opacity-80 backdrop-blur-sm shadow-md"
      }`}
    >
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Row align="middle" justify="start">
                <Col>
                  <div className="flex items-center">
                    <Link
                      to="/"
                      className="no-underline"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img
                        className="w-20 md:w-[calc(222px*(100vw/1000))]"
                        src={logo}
                        alt="Logo"
                      />
                    </Link>
                    <Link
                      to="/"
                      className="no-underline"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <WebsiteName />
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="flex items-center">
            <div className="hidden lg:block">
              <div className="flex space-x-4">
                <a
                  href="#top-games"
                  className="text-gray-800 hover:text-gray-600 px-3 py-2 rounded-md text-sm font-extrabold no-underline"
                  onClick={(e) => handleSmoothScroll(e, "top-games")}
                >
                  Top Games
                </a>
                <a
                  href="#other-games"
                  className="text-gray-800 hover:text-gray-600 px-3 py-2 rounded-md text-sm font-extrabold no-underline"
                  onClick={(e) => handleSmoothScroll(e, "other-games")}
                >
                  Other Games
                </a>
                {/* <a
                  href="#contact"
                  className="text-gray-800 hover:text-gray-600 px-3 py-2 rounded-md text-sm font-extrabold no-underline"
                  onClick={(e) => handleSmoothScroll(e, "contact")}
                >
                  Contact
                </a> */}
              </div>
            </div>
            <div className="lg:hidden">
              <Button
                type="text"
                size="large"
                icon={<MenuOutlined />}
                onClick={showDrawer}
              />
              <Drawer
                title="Menu"
                placement="right"
                onClose={onClose}
                open={visible}
              >
                <Menu mode="vertical">
                  <Item key="top-games">
                    <a
                      href="#top-games"
                      onClick={(e) => {
                        handleSmoothScroll(e, "top-games");
                        onClose();
                      }}
                      className="no-underline font-extrabold"
                    >
                      Top Games
                    </a>
                  </Item>
                  <Item key="other-games">
                    <a
                      href="#other-games"
                      onClick={(e) => {
                        handleSmoothScroll(e, "other-games");
                        onClose();
                      }}
                      className="no-underline font-extrabold"
                    >
                      Other Games
                    </a>
                  </Item>
                  {/* <Item key="contact">
                    <a
                      href="#contact"
                      onClick={(e) => {
                        handleSmoothScroll(e, "contact");
                        onClose();
                      }}
                      className="no-underline font-extrabold"
                    >
                      Contact
                    </a>
                  </Item> */}
                </Menu>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
