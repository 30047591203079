import React from "react";
import { NavigationBar } from "./components/NavigationBar";
import { TopGames } from "./components/TopGames";
import { OtherGames } from "./components/OtherGames";
import { Divider } from "antd";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

export const HomeScreen: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 via-blue-300 to-white-600">
      <NavigationBar />
      <Header />
      <div className="max-w-screen-lg mx-auto">
        {/* Top Games Components */}
        <div id="top-games">
          <Divider />
          <TopGames />
        </div>

        {/* Other Games Components */}
        <div id="other-games">
          <Divider />
          <OtherGames />
        </div>
      </div>
      <Footer />
    </div>
  );
};
