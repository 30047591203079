import "./styles.css";

export const Diamond = () => {
  return (
    <svg
      className="w-12 h-12 sm:w-20 sm:h-20 md:w-28 md:h-28 lg:w-28 lg:h-28 xl:w-28 xl:h-28 custom-animate-bounce"
      viewBox="0 0 97 139"
      fill="none"
    >
      <path
        d="M36.7783 27.3726L79.3896 59.5121L52.7245 86.8846L36.7783 27.3726Z"
        fill="#B5FFE3"
      ></path>
      <path
        d="M59.2314 111.165L15.9465 76.5116L52.7256 86.885L59.2314 111.165Z"
        fill="#1AD28D"
      ></path>
      <path
        d="M36.7789 27.3737L52.7249 86.885L15.946 76.5123L36.7789 27.3737Z"
        fill="#5CF9BE"
      ></path>
      <path
        d="M59.2301 111.163L52.7244 86.8839L79.3891 59.5106L59.2301 111.163Z"
        fill="#5CF9BE"
      ></path>
    </svg>
  );
};
