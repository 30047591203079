import { useState, useEffect } from "react";
import "./styles.css";
import { Layout, Image } from "antd";
import iphoneX_iframe from "../../assets/images/iphone.png";
import ipad_iframe from "../../assets/images/ipad.png";
import ip7_iframe from "../../assets/images/iphone_7.png";
import useWindowDimensions from "../../hooks";
import ipx_icon from "../../assets/images/device-icon-iphonex.png";
import ip7_icon from "../../assets/images/device-icon-iphone.png";
import ipad_icon from "../../assets/images/device-icon-ipad.png";
import { useLocation } from "react-router-dom";
import otherPlayables from "../../other-playables.json";
import topPlayables from "../../playables.json";

/**
 * @description check browser running in mobile or tablet.
 * @returns {boolean}
 */
const mobileAndTabletCheck = (): boolean => {
  let check = false;
  (function (a: string) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
};

interface DeviceState {
  deviceWidth: number;
  deviceHeight: number;
  deviceIframe: string;
  portrait: boolean;
}

export const PlayableScreen: React.FC = () => {
  const playables = topPlayables.concat(otherPlayables);
  const { width, height } = useWindowDimensions();

  const widthRatio = width / 850;
  const heightRatio = height / 1000;

  const location = useLocation();
  const pathnameArraySplit = location.pathname.split("/");
  const fileName = pathnameArraySplit[pathnameArraySplit.length - 1];
  const base = 46;

  const [ipX, setIpX] = useState<DeviceState>({
    deviceWidth: 7.2,
    deviceHeight: 15.3,
    deviceIframe: iphoneX_iframe,
    portrait: true,
  });
  const [ipad, setIpad] = useState<DeviceState>({
    deviceWidth: 9.7,
    deviceHeight: 12.9,
    deviceIframe: ipad_iframe,
    portrait: true,
  });
  const [ip7, setIp7] = useState<DeviceState>({
    deviceWidth: 7.1,
    deviceHeight: 12.4,
    deviceIframe: ip7_iframe,
    portrait: true,
  });

  const [iframe, setIframe] = useState<DeviceState>(ipX);
  const [memo, setMemo] = useState<string>(ipX.deviceIframe);
  const [activeDevice, setActiveDevice] = useState<string>(ipX.deviceIframe);

  const handleChangeIframe = (item: DeviceState): void => {
    const checkTwiceClick = memo === item.deviceIframe;
    const tempWidth = item.deviceWidth;
    const tempHeight = item.deviceHeight;

    const updatedState: DeviceState = {
      ...item,
      deviceWidth: checkTwiceClick ? tempHeight : item.deviceWidth,
      deviceHeight: checkTwiceClick ? tempWidth : item.deviceHeight,
      portrait: checkTwiceClick ? !item.portrait : item.portrait,
    };

    if (item.deviceIframe === ipX.deviceIframe) {
      setIpX(updatedState);
    } else if (item.deviceIframe === ipad.deviceIframe) {
      setIpad(updatedState);
    } else if (item.deviceIframe === ip7.deviceIframe) {
      setIp7(updatedState);
    }

    setIframe({
      ...updatedState,
      deviceIframe: item.deviceIframe,
    });
  };

  const mobileRenderMode = (): boolean => {
    if (mobileAndTabletCheck()) {
      return true;
    }
    return width < 512 || height < 512;
  };

  const isWidthScale = (
    deviceIframe: DeviceState,
    screenWidth: number
  ): boolean => {
    return screenWidth <= 850;
  };

  const isHeightScale = (
    deviceIframe: DeviceState,
    screenHeight: number
  ): boolean => {
    return screenHeight <= 920;
  };

  const refreshIframe = (): void => {
    const ifr = document.getElementsByName(
      "iframe-device"
    )[0] as HTMLIFrameElement;
    // eslint-disable-next-line no-self-assign
    ifr.src = ifr.src;
  };

  useEffect(() => {
    refreshIframe();
  }, [activeDevice]);

  return !mobileRenderMode() ? (
    <Layout
      className="flex flex-col items-start justify-start bg-image-playable"
      style={{ height: height, position: "relative", overflow: "hidden" }}
    >
      {/* 3 tabs to change iframe ipx ip7 ipad */}
      <div
        className="flex justify-center items-center mx-auto mt-3 space-x-20"
        style={{ zIndex: 999 }}
      >
        {[ipX, ipad, ip7].map((device) => (
          <div
            key={device.deviceIframe}
            className="flex justify-center items-center"
            onClick={() => {
              handleChangeIframe(device);
              setMemo(device.deviceIframe);
              setActiveDevice(device.deviceIframe);
            }}
          >
            <Image
              src={
                device === ipX
                  ? ipx_icon
                  : device === ipad
                  ? ipad_icon
                  : ip7_icon
              }
              style={{
                borderRadius: 7,
                transform: device.portrait ? "none" : "rotate(-90deg)",
              }}
              preview={false}
              className={`${
                activeDevice === device.deviceIframe ? "active-device" : ""
              } mx-auto`}
            />
          </div>
        ))}
      </div>

      {/* Iframe Container -> Iphone 7 , Iphone X, Ipad */}
      <div
        className="flex justify-center items-center mx-auto mt-5"
        style={{
          backgroundImage: `url(${iframe.deviceIframe})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          width: "100%",
          height: "100%",
          maxWidth:
            isWidthScale(iframe, width) && isHeightScale(iframe, width)
              ? 750 * widthRatio * heightRatio
              : isWidthScale(iframe, width)
              ? 750 * widthRatio
              : isHeightScale(iframe, height)
              ? 750 * heightRatio
              : 750,
          maxHeight:
            isWidthScale(iframe, width) && isHeightScale(iframe, width)
              ? 740 * widthRatio * heightRatio
              : isWidthScale(iframe, width)
              ? 740 * widthRatio
              : isHeightScale(iframe, height)
              ? 740 * heightRatio
              : 740,
          transform: iframe.portrait ? "rotate(0)" : "rotate(-90deg)",
        }}
      >
        <iframe
          title="iframe-device"
          name="iframe-device"
          src={
            playables.find((playable) => playable.alias === fileName)?.gameUrl
          }
          sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          allow="autoplay; fullscreen; geolocation; microphone; camera; midi; popup"
          onLoad={() => console.log("Iframe loaded successfully")}
          onError={(e) => {
            console.error("Failed to load iframe:", e);
            // You might want to set some state here to show an error message to the user
          }}
          style={{
            width:
              isWidthScale(iframe, width) && isHeightScale(iframe, width)
                ? base * iframe.deviceWidth * widthRatio * heightRatio
                : isWidthScale(iframe, width)
                ? base * iframe.deviceWidth * widthRatio
                : isHeightScale(iframe, height)
                ? base * iframe.deviceWidth * heightRatio
                : base * iframe.deviceWidth,
            height:
              isWidthScale(iframe, width) && isHeightScale(iframe, width)
                ? base * iframe.deviceHeight * widthRatio * heightRatio
                : isWidthScale(iframe, width)
                ? base * iframe.deviceHeight * widthRatio
                : isHeightScale(iframe, height)
                ? base * iframe.deviceHeight * heightRatio
                : base * iframe.deviceHeight,
            borderRadius: iframe.deviceIframe === ipX.deviceIframe ? 25 : 0,
            borderWidth: 0,
            transform: iframe.portrait ? "rotate(0)" : "rotate(90deg)",
          }}
        ></iframe>
      </div>
    </Layout>
  ) : (
    <iframe
      title="iframe-device"
      name="iframe-device"
      src={playables.find((playable) => playable.alias === fileName)?.gameUrl}
      onError={(e) => {
        console.error("Failed to load iframe:", e);
        // You might want to set some state here to show an error message to the user
      }}
      className="fixed inset-0 w-full h-full border-0 m-0 p-0 overflow-hidden z-50"
    ></iframe>
  );
};
