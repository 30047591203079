import React from "react";
import { Layout, Typography, Image } from "antd";
import {
  FacebookOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import logo_white from "../../../assets/images/logo_4_white.png";

const { Footer: AntFooter } = Layout;
const { Text } = Typography;

export const WebsiteName = () => {
  return (
    <div
      className="pl-2"
      style={{
        fontFamily: '"Germania One"',
        fontSize: "2rem",
        color: "white",
        fontWeight: "bold",
      }}
    >
      CloverAds
    </div>
  );
};

export const Footer: React.FC = () => {
  return (
    <AntFooter className="bg-gray-800 p-3 w-full">
      <div className="mx-auto max-w-screen-lg bg-transparent">
        <div className="flex flex-col md:flex-row items-center md:items-start w-full">
          <div className="flex flex-1 flex-col space-y-3 w-full md:w-1/3 mb-4 md:mb-0 px-10">
            <div className="flex items-center justify-center md:justify-start">
              <Image
                src={logo_white}
                alt="CloverAds Logo"
                preview={false}
                width={80}
                className="mr-2"
              />
              <WebsiteName />
            </div>
            <Text className="text-white italic text-center md:text-left text-xs sm:text-sm">
              &copy; Copyright 2024 CloverAds. All Rights Reserved.
            </Text>
          </div>

          <div className="flex flex-1 flex-col space-y-2 w-full md:w-1/3 mb-4 md:mb-0 pt-5">
            <Text className="text-white text-sm sm:text-base md:text-lg font-bold text-center md:text-left">
              Contact Info
            </Text>
            <div className="flex justify-center md:justify-start">
              <MailOutlined className="text-white mr-2 text-xs sm:text-sm md:text-base" />
              <Text className="text-white text-xs sm:text-sm md:text-base">
                yuutit@gmail.com
              </Text>
            </div>
          </div>
          <div className="flex flex-1 flex-col space-y-2 w-full md:w-1/3 pt-5">
            <Text className="text-white text-sm sm:text-base md:text-lg font-bold text-center md:text-left">
              Social Network
            </Text>
            <div className="flex items-center justify-center md:justify-start space-x-4">
              <a
                href="https://www.facebook.com/yuukitit"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full py-1 pr-1 flex items-center"
              >
                <FacebookOutlined className="text-white text-lg sm:text-xl md:text-2xl mr-1" />
                <span className="text-white text-xs sm:text-sm md:text-base font-semibold">
                  Facebook
                </span>
              </a>
              <a
                href="https://www.linkedin.com/in/to%C3%A0n-tr%C6%B0%C6%A1ng-anh-150b56a9"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full py-1 flex items-center"
              >
                <LinkedinOutlined className="text-white text-lg sm:text-xl md:text-2xl mr-1" />
                <span className="text-white text-xs sm:text-sm md:text-base font-semibold">
                  LinkedIn
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </AntFooter>
  );
};
