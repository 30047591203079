import React from "react";
import { Typography, Card, Row, Col, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import otherPlayables from "../../../other-playables.json";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const OtherGames: React.FC = () => {
  return (
    <div className="min-h-screen max-w-screen-lg mx-auto bg-transparent p-5 mx-5 pt-10">
      <Title level={2} className="text-center mb-8">
        Other Games
      </Title>
      <Row gutter={[24, 24]} className="bg-transparent">
        {otherPlayables.map((game, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={8}
            lg={8}
            className="flex bg-transparent px-5"
          >
            <Card
              cover={
                <Link
                  to={`/game-demo/${game.alias}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="h-48 w-full overflow-hidden rounded-3xl transition-transform duration-300 hover:scale-105">
                    <img
                      alt={game.title}
                      src={game.imageUrl}
                      className="w-full h-full object-cover rounded-3xl"
                    />
                  </div>
                </Link>
              }
              className="flex flex-col w-full border-none bg-transparent"
              bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className="flex flex-row flex-grow justify-between items-start">
                <div className="flex flex-col">
                  <Title level={4} className="mb-2">
                    {game.title}
                  </Title>
                  {/* <Paragraph className="text-gray-600 text-sm flex-grow">
                    {game.description}
                  </Paragraph> */}
                </div>
                <div className="self-start">
                  <Link
                    to={`/game-demo/${game.alias}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      type="primary"
                      size="large"
                      icon={<ArrowRightOutlined />}
                      className="bg-gradient-to-r from-[#5E17EB] to-[#8E2DE2] hover:from-[#4A11C0] hover:to-[#7A1ED0] rounded-full flex items-center justify-center"
                    />
                  </Link>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
