import React from "react";
import { HomeScreen } from "./screens/HomeScreen/HomeScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PlayableScreen } from "./screens/PlayableScreen/PlayableScreen";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/game-demo/:name" element={<PlayableScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
