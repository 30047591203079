export const YellowTree = () => {
  return (
    <svg
      className="w-16 h-24 sm:w-24 sm:h-32 md:w-32 md:h-40 lg:w-40 lg:h-48 xl:w-48 xl:h-56"
      viewBox="0 0 200 260"
      fill="none"
      style={{ animation: "none" }}
    >
      <path
        d="M132.643 37.2764L59.2176 51.0464L107.074 108.493L180.361 93.6507L132.643 37.2764Z"
        fill="#FED501"
      ></path>
      <path
        d="M115.995 84.9626L42.5692 98.7327L90.4251 156.18L163.712 141.337L115.995 84.9626Z"
        fill="#FED501"
      ></path>
      <path
        d="M137.967 21.7854L104.955 27.9763L126.471 53.8042L159.42 47.131L137.967 21.7854Z"
        fill="#FED501"
      ></path>
      <path
        d="M79.4004 187.577L37.2017 195.491L64.7052 228.507L106.824 219.977L79.4004 187.577Z"
        fill="#F6FAFF"
      ></path>
      <rect
        width="13.2453"
        height="40.2355"
        transform="matrix(-0.980361 0.197211 -0.331767 0.943361 95.5825 175.464)"
        fill="#AE8A42"
      ></rect>
      <rect
        width="13.2453"
        height="40.2896"
        transform="matrix(0.641101 0.767457 -0.331767 0.943361 74.1084 167.912)"
        fill="#CAA459"
      ></rect>
      <rect
        width="74.7055"
        height="37.1794"
        transform="matrix(-0.980361 0.197211 -0.331767 0.943361 180.348 93.6482)"
        fill="#DCA502"
      ></rect>
      <rect
        width="74.7055"
        height="37.1794"
        transform="matrix(-0.980361 0.197211 -0.331767 0.943361 163.702 141.332)"
        fill="#DCA502"
      ></rect>
      <rect
        width="33.5871"
        height="34.5897"
        transform="matrix(-0.980361 0.197211 -0.331767 0.943361 159.415 47.1282)"
        fill="#DCA502"
      ></rect>
      <rect
        width="42.9344"
        height="9.88964"
        transform="matrix(-0.980361 0.197211 -0.331767 0.943361 106.815 219.973)"
        fill="#B5C8E6"
      ></rect>
      <rect
        width="74.7055"
        height="37.1122"
        transform="matrix(0.641101 0.767457 -0.331767 0.943361 59.2178 51.0483)"
        fill="#FFEF7C"
      ></rect>
      <rect
        width="74.7055"
        height="37.1122"
        transform="matrix(0.641101 0.767457 -0.331767 0.943361 42.5708 98.7319)"
        fill="#FFEF7C"
      ></rect>
      <rect
        width="33.5871"
        height="34.5492"
        transform="matrix(0.641101 0.767457 -0.331767 0.943361 104.959 27.9768)"
        fill="#FFEF7C"
      ></rect>
      <rect
        width="42.9344"
        height="9.79901"
        transform="matrix(0.641101 0.767457 -0.331767 0.943361 37.2065 195.493)"
        fill="#D0DCEF"
      ></rect>
    </svg>
  );
};
